import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Ticks,
} from 'chart.js';

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartParams {
  chartData: unknown;
  accountKey: string;
}

function getChartOptions(accountKey: string) {
  return {
    scales: {
      dollarAxis: {
        type: 'linear',
        display: true,
        position: 'right',
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value: number, index: number, ticks: { value: number; }[]) {
            const formattedValue = Ticks.formatters.numeric.apply(this, [value, index, ticks]);
            return '$ ' + formattedValue;
          }
        }
      },
      pctAxis: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function(value: number, index: number, ticks: { value: number; }[]) {
            const formattedValue = Ticks.formatters.numeric.apply(this, [value, index, ticks]);
            return formattedValue + '%';
          }
        }
      }
    },
    plugins: {
      title: {
        display: true,
        text: accountKey
      },
      legend: {
        display: false,
        position: "bottom"
      }
    },
    layout: {
      padding: {
        top: -50
      }
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
  };
}
export const Chart = ({ chartData, accountKey }: ChartParams) => {
  return (
    <div className="mb-0">
      <Line
        data={chartData}
        options={getChartOptions(accountKey)} type={'line'}      />
    </div>
  );
};
