import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'reactstrap';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from '../config';
import Loading from '../components/Loading';
import { ChartContainer } from '../components/ChartContainer';
import { AccountPnlRow } from '../utils/accountPnl';

async function fetchApiResult<TResponse>(endpoint: string, accessToken: string): Promise<TResponse> {
  try {
    const baseUrl = `https://whale.mgh.im:5051`
    const fullUrl = `${baseUrl}/${endpoint}`

    const response = await fetch(fullUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const responseData: TResponse = await response.json();
    return responseData;
  } catch (error: any) {
    console.error(`Error callApiEndpoint(${endpoint}) `, error);
    throw error;
  }
}

export function PNLBoard() {
  const { audience } = getConfig();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: [] as string[],
    error: null,
  });

  const [accounts, setAccounts] = useState<string[]>([]);
  const [allData, setAllData] = useState<AccountPnlRow[]>([]);

  const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
    useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error: any) {
      setState({
        ...state,
        error: error?.error,
      });
    }

    await fetchAccounts();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error: any) {
      setState({
        ...state,
        error: error?.error,
      });
    }

    await fetchAccounts();
    await fetchAllData();
  };

  async function fetchAccounts() {
    try {
      const token = await getAccessTokenSilently();
      const responseData = await fetchApiResult<string[]>('accounts/pnl/accounts', token);
      if (Array.isArray(responseData)) {
        // Sorts account list to show the variants first, in order by variant number
        // non variants show at the end of the list
        const accountsSorted = responseData.sort((a, b) => {
          const aSplit = a.split(' ');
          const bSplit = b.split(' ');

          const defaultSort = a.localeCompare(b);
          if (aSplit.length !== 3 || bSplit.length !== 3) {
            return defaultSort;
          }

          const variantIndex = 2;
          const aVariant = aSplit[variantIndex];
          const bVariant = bSplit[variantIndex];

          // puts non-variant accs at the end of the list
          if (!aVariant.startsWith('v') || !bVariant.startsWith('v')) {
            return 10000 + a.localeCompare(b);
          }

          const aVariantNumber = Number(aVariant.replace('v',''));
          const bVariantNumber = Number(bVariant.replace('v',''));

          return aVariantNumber - bVariantNumber;
        });
        setAccounts(accountsSorted);
      }

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
      });

    } catch (error: any) {
      setState({
        ...state,
        error: error.error,
      });
    }
  }

  async function fetchAllData() {
    try {
      const token = await getAccessTokenSilently();
      const responseData = await fetchApiResult<AccountPnlRow[]>('accounts/pnl', token);
      if (Array.isArray(responseData)) {
        // store and sort in ascending (last is newest)
        const dataSorted = responseData.sort((a, b) => a.updateDt - b.updateDt);
        setAllData(dataSorted);
      }

      setState({
        ...state,
        showResult: true,
      });
    } catch (error: any) {
      setState({
        ...state,
        error: error.error,
      });
    }
  }

  async function refreshAll() {
    return Promise.allSettled([fetchAccounts(), fetchAllData()]);
  }

  // refresh every 30 secs
  useEffect(() => {
    refreshAll();
    const interval = setInterval(() => refreshAll(), 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function handle(
    e: { preventDefault: () => void; },
    fn: { (): Promise<void>; (): void; }
  ) {
    e.preventDefault();
    fn();
  }

  return (
    <div className="container-fluid">
      {state.error === 'consent_required' && (
        <Alert color="warning">
          You need to{' '}
          <a
            href="#/"
            className="alert-link"
            onClick={(e) => handle(e, handleConsent)}
          >
            consent to get access to users api
          </a>
        </Alert>
      )}

      {state.error === 'login_required' && (
        <Alert color="warning">
          You need to{' '}
          <a
            href="#/"
            className="alert-link"
            onClick={(e) => handle(e, handleLoginAgain)}
          >
            log in again
          </a>
        </Alert>
      )}

      {!audience && (
        <Alert color="warning">
          <p>
            You can't call the API at the moment because your application does
            not have any configuration for <code>audience</code>, or it is using
            the default value of <code>YOUR_API_IDENTIFIER</code>. You might get
            this default value if you used the "Download Sample" feature of{' '}
            <a href="https://auth0.com/docs/quickstart/spa/react">
              the quickstart guide
            </a>
            , but have not set an API up in your Auth0 Tenant. You can find out
            more information on{' '}
            <a href="https://auth0.com/docs/api">setting up APIs</a> in the
            Auth0 Docs.
          </p>
          <p>
            The audience is the identifier of the API that you want to call (see{' '}
            <a href="https://auth0.com/docs/get-started/dashboard/tenant-settings#api-authorization-settings">
              API Authorization Settings
            </a>{' '}
            for more info).
          </p>

          <p>
            In this sample, you can configure the audience in a couple of ways:
          </p>
          <ul>
            <li>
              in the <code>src/index.js</code> file
            </li>
            <li>
              by specifying it in the <code>auth_config.json</code> file (see
              the <code>auth_config.json.example</code> file for an example of
              where it should go)
            </li>
          </ul>
          <p>
            Once you have configured the value for <code>audience</code>, please
            restart the app and try to use the "pin API" button below.
          </p>
        </Alert>
      )}
      {/*
      <div className="result-block-container row">
        {state.showResult && (
          <div className="result-block-container" data-testid="api-result">
            <h6 className="muted">Result</h6>
            <Highlight>
              <span>{JSON.stringify(state.apiMessage, null, 2)}</span>
            </Highlight>
          </div>
        )}
      </div> */}

      <ChartContainer accounts={accounts} allData={allData}></ChartContainer>

      <Button
        color="primary"
        className="mt-12"
        onClick={refreshAll}
        disabled={!audience}
      >
        Refresh
      </Button>
    </div>
  );
}

export default withAuthenticationRequired(PNLBoard, {
  onRedirecting: () => <Loading />,
});
