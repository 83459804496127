import { useEffect, useState } from "react";
import { Chart } from "./Chart";
import { Row, Col } from "reactstrap";
import { AccountPnlRow, formatDate, getLastPropertyValue } from '../utils/accountPnl';

interface AccountChartInput {
  accKey: string;
  /** Data sorted in ascending order (newest is last) */
  filteredData: AccountPnlRow[];
}

interface HeaderProps {
  lastSeenBalance?: number | undefined;
  usdToday?: number | undefined;
  percentToday?: string | number | undefined;
  lastLeaderCount?: string | number | undefined;
  lastHedgeCount?: string | number | undefined;
  lastUpnlValue?: string | number | undefined;
  lastUpnlPercent?: string | number | undefined;
  lastDepthPercent?: string | number | undefined;
}

const colours = {
  blueDark: '#2a71d0',
  blueLight: '#dcf7fc',
};

function AccountChart(params: AccountChartInput) {
  const { accKey, filteredData } = params;

  const [chartData, setChartData] = useState({});
  const [headerProps, setHeaderProps] = useState<HeaderProps>({});

  useEffect(() => {
    try {
      setChartData({
        labels: filteredData.map(row => formatDate(row.updateDt)),
        datasets: [
          {
            id: 1,
            label: "$ Balance",
            data: filteredData.map(row => row.lastSeenBalance),
            // backgroundColor: [
            //   // "#ffbb11",
            //   // "#C0C0C0",
            //   // "#50AF95",
            //   // "#f3ba2f",
            //   "#2a71d0"
            // ],
            color: colours.blueDark,
            borderColor:  colours.blueDark,
            backgroundColor: [ colours.blueDark],
            yAxisID: 'dollarAxis',
          },
          {
            id: 2,
            label: "Pnl %",
            data: filteredData.map(row => row.dayPnlPct),
            color:  colours.blueLight,
            borderColor: colours.blueLight,
            backgroundColor: [colours.blueLight],
            yAxisID: 'pctAxis',
          }
        ],
      });

      const lastSeenBalance = getLastPropertyValue(filteredData, 'lastSeenBalance', '$', undefined,2);
      const usdToday = getLastPropertyValue(filteredData, 'dayPnlUsd', '$', undefined, 2);
      const percentToday = getLastPropertyValue(filteredData, 'dayPnlPct', undefined, '%', 2);
      const lastLeaderCount = getLastPropertyValue(filteredData, 'lastLeaderCount');
      const lastHedgeCount = getLastPropertyValue(filteredData, 'lastHedgeCount');
      const lastUpnlValue = getLastPropertyValue(filteredData, 'lastUpnlValue', '$', undefined, 0);
      const lastUpnlPercent = getLastPropertyValue(filteredData, 'lastUpnlPercent', undefined, '%', 0);
      const lastDepthPercent = getLastPropertyValue(filteredData, 'lastDepthPercent', undefined, '%', 0);


      // const dailyPct = filteredData.map(row => row.dayPnlPct);
      // const percentToday = dailyPct.length &&
        // dailyPct[dailyPct.length - 1] + "%";

      setHeaderProps({
        lastSeenBalance,
        usdToday,
        percentToday,
        lastLeaderCount,
        lastHedgeCount,
        lastUpnlValue,
        lastUpnlPercent,
        lastDepthPercent,
      });
    } catch (e) {
      console.error(`set chart data err`, { accKey, e});
    }
  }, [filteredData])


  let rowKey = 0;
  return (
    <div className="chartcard m-4">
      <h1 className="text-center mb-2">{accKey}</h1>
      <Row className="text-center mb-0" xs="3">
        {/* <Col key={rowKey++} className="mb-0">
        </Col> */}
        <Col key={rowKey++} className="text-center mb-0 ml-2"><h3>Today</h3></Col>
        <Col key={rowKey++} className="text-center mb-0">
          <h3>{headerProps.percentToday}</h3>
        </Col>
        <Col key={rowKey++} className="text-center mb-0">
          <h3>{headerProps.usdToday}</h3>
        </Col>
        <Col key={rowKey++} className="text-center mb-0 mr-2">
          <h3>{headerProps.lastSeenBalance}</h3>
        </Col>
      </Row>
      <Row className="text-center mb-0" xs="3">
        {/* <Col key={rowKey++} className="mb-0">
        </Col> */}
        <Col key={rowKey++} className="text-center mb-0 ml-2">
          <h3>L({headerProps.lastLeaderCount}) H({headerProps.lastHedgeCount})</h3>
        </Col>
        <Col key={rowKey++} className="text-center mb-0">
          <h3>Depth<br />{headerProps.lastDepthPercent}</h3>
        </Col>
        <Col key={rowKey++} className="text-center mb-0">
          <h3>Upnl<br />{headerProps.lastUpnlPercent}</h3>
        </Col>
        <Col key={rowKey++} className="text-center mb-0 mr-2">
          <h3>Upnl<br />{headerProps.lastUpnlValue}</h3>
        </Col>
      </Row>
      <Chart chartData={chartData} accountKey={accKey} />
    </div>
  );
}

function chartForAcc(accKey: string, allData: AccountPnlRow[]) {
  const data = allData.filter(r => r.accountKey === accKey);
  if (data.length) {
    return (
    <AccountChart
      accKey={accKey}
      key={accKey + "chart"}
      filteredData={data}
    ></AccountChart>);
  }
  return;
}

interface ChartParams {
  accounts: string[];
  allData: unknown;
}

export function ChartContainer({ accounts, allData }: ChartParams) {
  if (!accounts || !Array.isArray(accounts)) {
    return (<div>"Accounts" not an array: {accounts}</div>)
  }
  if (!allData || !Array.isArray(allData)) {
    return (<div>"allData" not an array: {accounts}</div>)
  }

  // console.log('accounts: ', accounts);
  // const accKey = "sb4 dev 8x";
  // const accountsOld = ["jenny1","mike1","sb1 dev 4x","sb10 duck","sb3 FKT TS","sb4 dev 8x","sb9 ndev"];
  // const set1 = accounts.filter(acc => ["sb4 dev 8x"].includes(acc));
  // const set2 = accounts.filter(acc => ["sb9 ndev"].includes(acc));
  // const exclusion = accounts.filter(acc => ["sb1 dev 4x","sb10 duck","jenny1","mike1"].includes(acc));

  const hiddenAccounts = ["sb1 dev 4x","sb10 duck","jenny1","mike1"];
  const setRest = accounts.filter(acc => !hiddenAccounts.includes(acc));

  // debugger;
  // const setRest = accounts.filter(acc => ![...set1, ...set2,...exclusion].includes(acc));

  // const charts1 = set1.sort().map(accKey => chartForAcc(accKey, allData));
  // const charts2 = set2.sort().map(accKey => chartForAcc(accKey, allData));

  const chartsRest = setRest.map(accKey => chartForAcc(accKey, allData));
  // const chartsTrash = trash.sort().map(accKey => chartForAcc(accKey, allData));

  return (
    <div className="container-fluid">
      <div className="cards" id="cardsGroup">
      {/* {charts1}
      {charts2} */}
      {chartsRest}
      {/* {chartsTrash} */}
        {/* <AccountChart accKey={accKey}></AccountChart>
        <AccountChart accKey="jenny1"></AccountChart> */}
      </div>
    </div>
  );
}
