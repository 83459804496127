import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


function Home() {
  const { isLoading, isAuthenticated, error } = useAuth0();
  let history = useHistory();

  function openPnlBoard(e) {
    e.preventDefault();
    history.push('/pnl');
  }

  if (isAuthenticated) {
    return (
      <div className="container-fluid mt-5">
        <h2 className="text-center">
          Navigate to the <a href="" onClick={(e) => openPnlBoard(e)}>PNL Board</a>
        </h2>
      </div>
    )
  }
  return (
    <Fragment>
      Authenticate using the menu on the top-right corner.
    </Fragment>
  );
}

export default Home;
