export interface AccountPnlRow {
  accountKey: string; // accountKey
  date: string; // yyyy-mm-dd
  dayPnlPct: number; // % value of pnl
  dayPnlUsd: number; // balance vs yesterday
  id: number;
  lastSeenBalance: number; // last seen balance
  startBalance: number; // balance at very beginning
  updateDt: number; // timestamp updated
  viewTags: string; // csv string, used server-side
  lastLeaderCount: number;
  lastHedgeCount: number;
  lastUpnlValue: number;
  lastUpnlPercent: number;
  lastDepthPercent: number;
}

export function getLastPropertyValue<TProperty extends keyof AccountPnlRow>(
  array: AccountPnlRow[],
  property: TProperty,
  prefix: string | undefined = '',
  suffix: string | undefined = '',
  decimalTrimTo?: number | undefined,
): any {
  return getLastValue(
    array.map((r) => r[property]),
    prefix,
    suffix,
    decimalTrimTo,
  );
}

export function getLastValue<TProperty extends number | string>(
  valArray: TProperty[],
  prefix: string,
  suffix: string,
  decimalTrimTo?: number | undefined,
): any {
  if (!Array.isArray(valArray) || !valArray.length) {
    return;
  }

  const value = valArray[valArray.length - 1];
  if (typeof valArray === 'string') {
    return value;
  }

  const numberValue = Number(value);
  const finalValue =
    typeof decimalTrimTo === 'number'
      ? numberValue.toFixed(decimalTrimTo)
      : numberValue;

  if (numberValue < 0) {
    return `-${prefix}${Math.abs(Number(finalValue))}${suffix}`;
  }
  return `${prefix}${finalValue}${suffix}`;
}

export function formatDate(timestamp: number): string {
  const dt = new Date(timestamp);
  return dt.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'numeric',
  });
}
